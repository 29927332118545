import { AjudaComponent } from './ajuda/ajuda.component';
import { AccordionModule } from 'primeng/accordion';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CommonModule, DatePipe } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { InterceptorModule } from './core/intercepor/auth.interceptor.module';
import { LoginModule } from './login/login.module';
import { ManutencaoGrupoComponent } from './manutencao-grupo/manutencao-grupo.component';
import { ManutencaoProcessamentoComponent } from './manutencao-processamento/manutencao-processamento.component';
import { ManutencaoUsuarioComponent } from './manutencao-usuario/manutencao-usuario.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { PhonePipe } from './shared/phone-pipe/phone.pipe';
import { ProcessamentoDocumentosComponent } from './processamento-documentos/processamento-documentos.component';
import { ProcessamentoListaComponent } from './processamento-lista/processamento-lista.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ConfigCNPJEstabComponent } from './config-cnpj-estab/config-cnpj-estab.component';
import { ProntidaoDefinicaoComponent } from './prontidao-definicao/prontidao-definicao.component';

@NgModule({
  declarations: [
    AjudaComponent,
    AppComponent,
    ConfigCNPJEstabComponent,
    ProntidaoDefinicaoComponent,
    ManutencaoGrupoComponent,
    ManutencaoProcessamentoComponent,
    ManutencaoUsuarioComponent,
    MenuBarComponent,
    PhonePipe,
    ProcessamentoDocumentosComponent,
    ProcessamentoListaComponent
  ],
  imports: [
    AccordionModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CommonModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    InputMaskModule,
    InputTextareaModule,
    InputTextModule,
    InterceptorModule,
    LoginModule,
    MenubarModule,
    MessagesModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PasswordModule,
    RadioButtonModule,
    TableModule,
    TabViewModule,
    ToastModule,
    ToolbarModule,
    TriStateCheckboxModule,
    ToggleButtonModule
  ],
  providers: [
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
