import { AuthService } from '../core/auth/service/auth.service';
import { catchError } from 'rxjs/operators';
import { Companhia } from '../modelo/Companhia';
import { CompanhiaService } from '../companhia/companhia.service';
import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { HttpErrorResponse } from '@angular/common/http';
import { interval, throwError } from 'rxjs';
import { PageProps } from '../modelo/PageProps';
import { Processamento } from '../modelo/Processamento';
import { ProcessamentoService } from './manutencao-processamento.service';
import { SharedDataService } from '../shared/shared-data/shared-data.service';
import { ProcessamentoLista } from '../modelo/ProcessamentoLista';

@Component({
  selector: 'app-manutencao-processamento',
  templateUrl: './manutencao-processamento.component.html',
  styleUrls: ['./manutencao-processamento.component.css'],
  providers: [MessageService, ConfirmationService, FileUploadModule]
})
export class ManutencaoProcessamentoComponent {
  @ViewChild(FileUpload) fileUpload!: FileUpload;

  apenasErros: boolean = true;
  detalhamentoDialog: boolean = false;
  dialog: boolean = false;
  display: boolean = false;
  lifeMessageToast: number = 5000;
  listaArquivos: any[] = [];
  listaCompanhias: Companhia[] = [];
  loading: boolean = false;
  processamento: Processamento = new Processamento();
  processamentoListaPage: PageProps<ProcessamentoLista> = {} as PageProps<ProcessamentoLista>;
  processamentoPage: PageProps<Processamento> = {} as PageProps<Processamento>;
  processamentoListas: ProcessamentoLista[] = [];
  processamentoErrosTIpo: any[] = [];
  processamentoErrosPage: PageProps<any> = {} as PageProps<any>;
  processamentos: Processamento[] = [];
  submitted!: boolean;
  uploadDialog: boolean = false;
  visible: boolean = false;



  constructor(
    private companhiaService: CompanhiaService,
    private messageService: MessageService,
    private service: ProcessamentoService,
    private sharedData: SharedDataService,
    private auth: AuthService
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.fetchContent();
    this.getCompanhias();

    interval(2000) // Atualiza a cada 50 segundos (50000 milissegundos)
    .subscribe(() => {
      if (this.detalhamentoDialog) {
        this.fetchContent();
        this.getListaArquivos();
        this.getListaErros();  
      }
    });
    this.loading = false;
    this.display = false;
  }

  public getListaErros(): void {
    if(!this.detalhamentoDialog)
      return;
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.listarErros(this.processamento).subscribe({
      next: (payload) => {
        this.processamentoErrosPage = payload;
        this.processamentoErrosTIpo = this.processamentoErrosPage.content;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Arquivos (Erros)',
          detail: 'Erro ao tentar buscar lista de erros!',
          life: this.lifeMessageToast,
        });
      }
    })
    this.loading = false;
  }

  public getListaArquivos(): void {
    if(!this.detalhamentoDialog && !this.uploadDialog)
      return;
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.listarArquivos(this.processamento).subscribe({
      next: (payload) => {
        this.processamentoListaPage = payload;
        this.processamentoListas = this.processamentoListaPage.content;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Arquivos (Listas)',
          detail: 'Erro ao tentar buscar lista de arquivos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      }
    })
    this.loading = false;
  }

  public fetchContent(): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.listar().subscribe({
      next: (payload) => {
        this.processamentoPage = payload;
        this.processamentos = this.processamentoPage.content;
    },

      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Arquivos',
          detail: 'Erro ao tentar buscar lista de arquivos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  getIconClass(status: string): String | undefined | null {
    if (status == 'Concluído com sucesso')
      return 'pi pi-check p-button-success';
    if (status == 'Falha processamento')
      return 'pi pi-times p-button-danger';
    if (status == 'Processamento cancelado')
      return 'pi pi-exclamation-triangle p-button-warning';
    if (status == 'Pendente')
      return 'pi pi-stopwatch p-button-help';
    if (status == 'Em processamento')
      return 'pi pi-sync pi-spin p-button-info';
    if (status == "Aguardando arquivos")
      return 'pi pi-clock p-button-help';
    if (status == "Pronto para iniciar")
      return "pi pi-directions";


    return '';
  }

  //abre dialog
  openNew() {
    this.submitted = false;
    this.dialog = true;
    this.visible = true;
    this.processamento = new Processamento();
  }

  hideDetalhamentoDialog() {
    this.detalhamentoDialog = false;
  }

  //fecha dialog
  hideDialog() {
    this.visible = false;
    this.submitted = false;
  }

  salvar() {
    this.submitted = true;
    try {
        this.gravarInclusao();
    }
    catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: 'Não foi possível salvar programação!',
        life: this.lifeMessageToast,
      });
    }
  }

  gravarInclusao() {
    this.service.salvar(this.processamento)
    .pipe(
      catchError(error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Processamento',
            detail: 'Erro ao salvar programação. ' + error.error.error,
            life: this.lifeMessageToast,
          });
          return throwError(error);
      }))
      .subscribe((response) => {
        this.fetchContent();
        this.visible = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Processamento',
          detail: 'Programação de Carga salva com sucesso!',
          life: this.lifeMessageToast});
    });
  }

  upload(processamento: Processamento) {
    this.processamento = { ...processamento};
    this.uploadDialog = true;
    this.getListaArquivos();
  }

  solicitaProcessamento(processamento: Processamento) {
    this.service.solicitaProcessamento(processamento)
    .pipe(
      catchError(error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento',
          detail: 'Erro ao solicitar inicio do processamento. ' + error,
          life: this.lifeMessageToast,
        });
        return throwError(error);
      }))
    .subscribe((response) => {
      this.fetchContent();
      this.messageService.add({
        severity: 'success',
        summary: 'Processamento',
        detail: 'Solicitação de início do processamento realizada com sucesso.',
        life: this.lifeMessageToast});
    })
  }

  solicitaCancelamento(processamento: Processamento) {
    this.service.solicitaCancelamento(processamento)
    .pipe(
      catchError(error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento',
          detail: 'Erro ao solicitar cancelamento do processamento. ' + error,
          life: this.lifeMessageToast,
        });
        return throwError(error);
      }))
    .subscribe((response) => {
      this.fetchContent();
      this.messageService.add({
        severity: 'success',
        summary: 'Processamento',
        detail: 'Solicitação de cancelamento do processamento realizada com sucesso.',
        life: this.lifeMessageToast});
    })
  }

  exibeLogProcessamento(processamento: Processamento) {
      this.processamentoListas = [];
      this.processamentoErrosTIpo = [];
      this.processamento = { ...processamento};
      this.getListaArquivos();
      this.getListaErros();
      this.detalhamentoDialog = true;
  }

  exportar() {
    this.service.exportar(this.processamento, this.apenasErros).subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'exportacao_erros_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + '.xlsx'
      link.href = url;
      link.click();
      window.URL.revokeObjectURL(url);
    })
  }

  salvarArquivo(processamentoLista: ProcessamentoLista) {
    this.service.salvarArquivo(processamentoLista)
    .pipe(
      catchError(error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento',
          detail: 'Erro ao salvar Arquivo. ' + error,
          life: this.lifeMessageToast,
        });
        return throwError(error);
      }))
    .subscribe((response) => {
      this.fetchContent();
      this.messageService.add({
        severity: 'success',
        summary: 'Arquivos',
        detail: 'Arquivo salvo com sucesso.',
        life: this.lifeMessageToast});
    })
  }

  excluirArquivo(processamentoLista: ProcessamentoLista) {
    this.service.excluirArquivo(processamentoLista)
    .pipe(
      catchError(error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento',
          detail: 'Erro ao salvar programação. ' + error.error.error,
          life: this.lifeMessageToast,
        });
        return throwError(error);
      }))
    .subscribe((response) => {
      this.getListaArquivos();
      this.fetchContent();
      this.messageService.add({
        severity: 'success',
        summary: 'Arquivos',
        detail: 'Arquivo removido da programação com sucesso.',
        life: this.lifeMessageToast});
    })
  }

  //dialog do botão de anexo
  uploadFile(event: any) {
    this.loading = true;
    try {
      for (let file of event.files) {
        this.service.uploadFile(file, this.processamento).subscribe({
          next: (response) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Upload',
                detail: 'Upload concluido;',
                life: this.lifeMessageToast,
              });
              this.fileUpload.clear();
              this.fetchContent();
              this.getListaArquivos();

            //}
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Upload',
              detail: 'Erro: ' + err.error,
              life: this.lifeMessageToast,
            });
          },
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Upload',
        detail: 'Erro ao tentar fazer Upload!',
        life: this.lifeMessageToast,
      });
    }
  }

  getCompanhias() {
    this.companhiaService.getCompanhias().subscribe(
      (response) => {

        this.listaCompanhias = this.listaCompanhias.concat(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  marcarTodos() {
      this.processamento.procFiliais = true;
      this.processamento.procMateriais = true;
      this.processamento.procParticipantes = true;
      this.processamento.procNotasFiscais = true;
      this.processamento.procAuditoriaFiscal = true;
      this.processamento.procEstabelecimentoFiscalECF = true;
      this.processamento.procParteALalur = true;
      this.processamento.procParteBLalur = true;
      this.processamento.procPlanoContas = true;
      this.processamento.procCentroCusto = true;
      this.processamento.procLancamentosContabeis = true;
      this.processamento.procSaldosContabeis = true;
      this.processamento.procPlanoReferencial = true;
      this.processamento.procAglutinacoes = true;
      this.processamento.procLivroContabil = true;
  }

  desmarcarTodos() {
      this.processamento.procFiliais = false;
      this.processamento.procMateriais = false;
      this.processamento.procParticipantes = false;
      this.processamento.procNotasFiscais = false;
      this.processamento.procAuditoriaFiscal = false;
      this.processamento.procEstabelecimentoFiscalECF = false;
      this.processamento.procParteALalur = false;
      this.processamento.procParteBLalur = false;
      this.processamento.procPlanoContas = false;
      this.processamento.procCentroCusto = false;
      this.processamento.procLancamentosContabeis = false;
      this.processamento.procSaldosContabeis = false;
      this.processamento.procPlanoReferencial = false;
      this.processamento.procAglutinacoes = false;
      this.processamento.procLivroContabil = false;
  }

  verificaStatusProtocolos(processamento: Processamento) {
    this.display = true;
    this.service.statusProtocolo(processamento).subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'status_protocolos_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString() + '.xlsx'
      link.href = url;
      link.click();
      window.URL.revokeObjectURL(url);
      this.display = false;
    })
  }
}
