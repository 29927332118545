import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class ConfigCNPJEstabService {
    apiUrl: string = `${environment.backendUrl}/config/cnpj`;

    constructor(private http: HttpClient) { }

    listar() {
      return this.http.get<IApiResponse>(`${this.apiUrl}`);
    }

    salvar(configuracoes: any): Observable<any> {
      return this.http.post(`${this.apiUrl}`, configuracoes);
    }

  }
