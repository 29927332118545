<app-menu-bar></app-menu-bar>
<div class="prontidao-container">
<div style="text-align: center">Prontidão - Definição de Escopo</div>
<p-toolbar>
      <ng-template pTemplate="right">
        <div class="card flex justify-content-center">
          <p-toast></p-toast>
        </div>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          label="Importar"
          class="p-button-info mr-2"
          (click)="openImport()"
        ></button>
      </ng-template>
    </p-toolbar>
    <div class="table-container">
        <p-table 
            styleClass="p-datatable-gridlines" 
            [tableStyle]="{ 'min-width': '50rem' }" 
            [paginator]="true" 
            [rows]="15"
            [rowsPerPageOptions]="[10, 15, 20]" 
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
            [value]="definicoes" 
            [globalFilterFields]="[
                  'dados.obrigacao',
                  'dados.registrosContidos']">
            <ng-template pTemplate="header">
                <tr>
                    <th id="obrigacao" style="text-align: center">Obrigação</th>
                    <th id="registrosContidos" style="text-align: center">Registros atendidos</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>
                    <td style="text-align: left">{{ dados.obrigacao }}</td>
                    <td style="text-align: left">{{ dados.registrosContidos }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-dialog 
        header="Upload de novo arquivo" 
        [(visible)]="importDialog" 
        [modal]="true" 
        styleClass="p-fluid">

        <ng-template pTemplate="content">
            <div>
                <div class="upload-container">
                    <p-fileUpload 
                        name="file[]" 
                        [customUpload]="true" 
                        (uploadHandler)="uploadFile($event)"
                        (onUpload)="uploadFile($event)" 
                        [multiple]="false" 
                        accept=".xlsx" 
                        mode="basic" 
                        [auto]="true"
                        [maxFileSize]="1_000_000" 
                        chooseLabel="Selecionar Arquivo" 
                        cancelLabel="Cancelar" 
                        uploadLabel="Enviar"
                        uploadIcon="pi pi-cloud-upload">
                    </p-fileUpload>
                </div>
            </div>
        </ng-template>
    </p-dialog>


</div>
