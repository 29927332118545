
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Processamento } from '../modelo/Processamento';

@Injectable({
    providedIn: 'root'
})
export class ProntidaoAvaliacaoService {

    apiUrl: string = `${environment.backendUrl}/prontidao/avaliacao`;

    constructor(private http: HttpClient) { }

    validaProntidao(processamento: Processamento): Observable<any> {
        return this.http.post(`${this.apiUrl}`, processamento);
    }

    listarAvaliacao(processamento: Processamento): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/${processamento.uuid}`);
    }

}