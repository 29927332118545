import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanhiaService {

  apiUrl: string = `${environment.backendUrl}/companhia`;

  constructor(private http: HttpClient) { }

  getCompanhias (){
    let searchParams = new HttpParams();

    return this.http.get<IApiResponse>(`${this.apiUrl}`,{ params: searchParams });
  }

}
