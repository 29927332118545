<div class="login-container">
  <p-toast></p-toast>
  <div class="login-box">
    <div style="text-align: center">
      <img src="../../assets/images/Avalara.png"
      class="Header__Main__Logo__Primary"
      alt="Logo da sua aplicação">
    </div>
    <div style="text-align: center">
      <h2>ACELERADOR IMPLANTAÇÃO TCO</h2>
    </div>
    <div class="p-fluid">
      <!-- Campo de Usuario -->
      <form [formGroup]="form" (ngSubmit)="handleSubmit()">
        <div class="p-field">
          <label for="nome"> Usuário: </label>
          <input
            class="custom-input"
            pInputText
            id="nome"
            placeholder="Usuário"
            type="text"
            formControlName="nome" />
        </div>
        <!-- Campo de Senha -->
        <div class="p-field">
          <label> Senha: </label>
          <p-password
            id="password"
            placeholder="Senha"
            formControlName="senha"
            [toggleMask]="true"
            class="custom-input" />
        </div>

        <!-- Botão Entrar -->
        <p-button type="submit" [disabled]="form.invalid || (form.touched && showLoader)" label="Entrar" class="custom-button"></p-button>
      </form>
    </div>
  </div>
</div>
<footer>
  <div style="text-align: left">F:0.4.4</div>
  <div style="text-align: left">B:{{ backendVersion }}</div>

</footer>

