<app-menu-bar></app-menu-bar>
<div class="config-container">
    <div style="text-align: center">CNPJ x Contribuinte</div>
    <p-toast></p-toast>
    <div class="table-container">
        <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="configuracoes" [paginator]="true" [rows]="15"
            [rowsPerPageOptions]="[10, 15, 20]" [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

            <ng-template pTemplate="header">
                <tr>
                    <th id="codigoCia" pSortableColumn="codigoCia" style="text-align: center">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex center-content-between align-items-center">Companhia</div>
                            <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="codigoCia"></p-sortIcon>
                                <p-columnFilter type="text" field="codigoCia" display="menu" matchMode="contains" />
                            </div>
                        </div>
                    </th>

                    <th id="nomeCia" pSortableColumn="nomeCia" style="text-align: center">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex center-content-between align-items-center">Nome da Companhia</div>
                            <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="nomeCia"></p-sortIcon>
                                <p-columnFilter type="text" field="nomeCia" display="menu" matchMode="contains" />
                            </div>
                        </div>
                    </th>

                    <th id="codContribuinte" pSortableColumn="codContribuinte" style="text-align: center">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex center-content-between align-items-center">Contribuinte</div>
                            <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="codContribuinte"></p-sortIcon>
                                <p-columnFilter type="text" field="codContribuinte" display="menu"
                                    matchMode="contains" />
                            </div>
                        </div>
                    </th>

                    <th id="nomeContribuinte" pSortableColumn="nomeContribuinte" style="text-align: center">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex center-content-between align-items-center">Nome do Contribuinte</div>
                            <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="nomeContribuinte"></p-sortIcon>
                                <p-columnFilter type="text" field="nomeContribuinte" display="menu"
                                    matchMode="contains" />
                            </div>
                        </div>
                    </th>

                    <th id="cnpj" pSortableColumn="cnpj" style="text-align: center">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex center-content-between align-items-center" style="text-align: center">CNPJ
                            </div>
                            <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="cnpj"></p-sortIcon>
                                <p-columnFilter type="text" field="cnpj" display="menu" matchMode="contains" />
                            </div>
                        </div>
                    </th>
                    <th id="grupoContabil" pSortableColumn="grupoContabil" style="text-align: center">
                        <div class="flex justify-content-between align-items-center">
                            <div class="flex center-content-between align-items-center" style="text-align: center">
                                Grupo Contabil
                            </div>
                            <div class="flex justify-content-between align-items-center">
                                <p-sortIcon field="grupoContabil"></p-sortIcon>
                                <p-columnFilter type="text" field="grupoContabil" display="menu" matchMode="contains" />
                            </div>
                        </div>
                    </th>
                    <th style="text-align: center">Operação</th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>
                    <td style="text-align: left">{{ dados.codigoCia }}</td>
                    <td style="text-align: left">{{ dados.nomeCia }}</td>
                    <td style="text-align: left">{{ dados.codContribuinte }}</td>
                    <td style="text-align: left">{{ dados.nomeContribuinte }}</td>
                    <td pEditableColumn style="text-align: left">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-inputMask mask="99.999.999/9999-99" [(ngModel)]="dados.cnpj" />
                            </ng-template>
                            <ng-template pTemplate="output">
                                <i class="pi pi-pencil">&nbsp;{{dados.cnpj}}</i>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn style="text-align: left">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="dados.grupoContabil" />
                            </ng-template>
                            <ng-template pTemplate="output">
                                <i class="pi pi-pencil">&nbsp;{{dados.grupoContabil}}</i>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="text-align: center">
                        <i class="pi pi-save" style="color: green; font-size: 1rem;" pTooltip="Salvar"
                            (click)="salvarConfig(dados)">&nbsp;Salvar</i>&nbsp;&nbsp;
                        <i class="pi pi-eraser" style="color: red; font-size: 1rem;" pTooltip="Limpar"
                            (click)="fetchContent()">&nbsp;Limpar</i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>