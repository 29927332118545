import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ManutencaoGrupoComponent } from './manutencao-grupo/manutencao-grupo.component';
import { ManutencaoUsuarioComponent } from './manutencao-usuario/manutencao-usuario.component';
import { AuthGuard } from './core/auth/guard/auth.guard';
import { ManutencaoProcessamentoComponent } from './manutencao-processamento/manutencao-processamento.component';
import { ProcessamentoListaComponent } from './processamento-lista/processamento-lista.component';
import { ProcessamentoDocumentosComponent } from './processamento-documentos/processamento-documentos.component';
import { AjudaComponent } from './ajuda/ajuda.component';
import { ConfigCNPJEstabComponent } from './config-cnpj-estab/config-cnpj-estab.component';
import { ProntidaoDefinicaoComponent } from './prontidao-definicao/prontidao-definicao.component';

const routes: Routes = [
  {
    path: '',
    component: ManutencaoProcessamentoComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: "config-cnpj-estab",
    component: ConfigCNPJEstabComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'config-definicao-prontidao',
    component: ProntidaoDefinicaoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manutencao-grupo',
    component: ManutencaoGrupoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manutencao-processamento',
    component: ManutencaoProcessamentoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manutencao-usuario',
    component: ManutencaoUsuarioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lista',
    component: ProcessamentoListaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registros',
    component: ProcessamentoDocumentosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ajuda',
    component: AjudaComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
