import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Processamento } from '../modelo/Processamento';
import { Observable } from 'rxjs';
import { ProcessamentoLista } from '../modelo/ProcessamentoLista';

@Injectable({
  providedIn: 'root'
})
export class ProcessamentoListaService {

  apiUrl: string = `${environment.backendUrl}/processamento`;

  constructor(private http: HttpClient) { }

  getListas(arquivoId: String | undefined | null): Observable<ProcessamentoLista[]> {
      let searchParams = new HttpParams();
      return this.http.get<ProcessamentoLista[]>(`${this.apiUrl}/lista/${arquivoId}`);
    }

  getExportacoes ( listaId: number | undefined): Observable<Blob>{
    let searchParams = new HttpParams();

    return this.http.post<any>(`${this.apiUrl}/lista`, listaId,
    { responseType: 'blob' as 'json' });
  }
}
