
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProntidaoDefinicaoService {

    apiUrl: string = `${environment.backendUrl}/prontidao/definicao`;

    constructor(private http: HttpClient) { }

    uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${this.apiUrl}`, formData, { observe: 'response', responseType: 'text' });
    }

    listar(): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}`);
    }

}