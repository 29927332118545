import { AuthService } from '../core/auth/service/auth.service';
import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ProntidaoDefinicaoService } from './prontidao-definicao.service';
import { ProntidaoDefinicao } from '../modelo/ProntidaoDefinicao';

@Component({
    selector: 'app-config-prontidao-definicao',
    templateUrl: './prontidao-definicao.component.html',
    styleUrls: ['./prontidao-definicao.component.css'],
    providers: []
  })
  export class ProntidaoDefinicaoComponent {

  definicoes: any[] = [];
  importDialog: boolean = false;
  lifeMessageToast: number = 5000;
  loading: boolean = false;

  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private service: ProntidaoDefinicaoService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.fetchContent();

    this.loading = false;
  }

  public fetchContent(): void { 
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.listar().subscribe({
      next: (payload) => {        
        this.definicoes = payload;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Arquivos',
          detail: 'Erro ao tentar buscar lista de arquivos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      }
    });
  }

  //abre dialog
  openImport() {
    this.importDialog = true;
  }

  uploadFile(event: any) {
    this.loading = true;
    try {
      for (let file of event.files) {
        this.service.uploadFile(file).subscribe({
          next: (response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Upload',
              detail: 'Upload concluído;',
              life: this.lifeMessageToast,
            });
            this.fetchContent();
            this.importDialog = false;
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Upload',
              detail: 'Erro: ' + err.error,
              life: this.lifeMessageToast,
            });
          }
        });
      }
    }
    catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Upload',
        detail: 'Erro ao tentar fazer Upload!',
        life: this.lifeMessageToast,
      });
    }
  }

  }