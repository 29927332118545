import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILogin } from 'src/app/@types/ILogin';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

const AUTH_ENDPOINT = `${environment.auth}`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ACCESS_TOKEN_KEY = '@PortalProjo:access_token';
  private readonly REFRESH_TOKEN_KEY = '@PortalProjo:refresh_token';
  private jwtHelper = new JwtHelperService();

  constructor(
    private _http: HttpClient,
    private router: Router) {}

  isAuthenticated(): boolean {
    const token = this.getToken();
    const valido = !!token && !this.jwtHelper.isTokenExpired(token);
    if (!valido)
      this.router.navigate(['login'])
    return valido;
  }

  isFirstAccess(): boolean {
    const token: any = this.jwtHelper.decodeToken(this.getToken()!) as any;
    const { user_first_access } = token;
    return user_first_access;
  }

  getToken(): string | null {
    return sessionStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  signIn(login: ILogin)  {
    return this._http.post(AUTH_ENDPOINT + '/securiy/login', login, { responseType: 'text' as const  })
      .pipe(
        tap(response => {
          const accessToken: string = response as unknown as string;
          sessionStorage.setItem("usuarioLogin", login.nome);
          this.initializeSession(accessToken);
        }),
        map(response => response as unknown as string)
      );
  }

  getVersion(): Observable<string> {
    return this._http.get<string>(AUTH_ENDPOINT + '/securiy/login/version');
  }


  obterUsuarioLogado(): string | null {
     return sessionStorage.getItem("usuarioLogin");
  }

  obterGruposUsuario(): string[] {
    const token = this.getToken();
    if (token) {
      const decodedToken: any = this.jwtHelper.decodeToken(token);
      console.log('Token decodificado:', decodedToken);
      console.log('Grupos do usuário:', decodedToken.sub);
      return decodedToken.sub || [];
    }
    return [];
  }

  initializeSession(accessToken: any) {
    sessionStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
  }

  destroySession = (): void => {
    sessionStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }

}
